import { config } from "@/lib/config";
import parseUserJson from "@/lib/utils/admin/parseUserJson";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { useUserAuth } from "@/lib/utils/hooks/useUserAuth";
import { useSession } from "next-auth/client";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { PlayerContext } from "../player/player-wrapper";
import ProfileLayout from "./item/profile-layout";
import ProfileMissing from "./profile-missing";
import { getStreamUrl } from "@/lib/utils/getStreamUrl";



function ProfileHomeContainer({
     ssr_data_tribe
    ,ssr_data_profile
    ,ssr_data_schedule
    ,params
    ,navtype
    ,helptype
    ,display_view
    ,set_display_view
    ,change_cache
    ,set_change_cache
    ,children

}) {

  const {player_state,set_player_state} = useContext(PlayerContext)

  //PRELOAD SSR DATA
  const [profiledata_active, set_profiledata_active] = useState(ssr_data_profile)
  const [scheduledata_active, set_scheduledata_active] = useState(ssr_data_schedule)

  const router = useRouter()

  const [cache, set_cache] = useState('')
  useEffect(()=>{
    let cache_date = +new Date;
    let cache_date_encoded = encodeURIComponent(cache_date?.toString())
    set_cache(cache_date_encoded)
  },[])

    const [user_name,set_user_name] = useState(params?.user_name?.toString()) //router?.query?.user_name?.toString()
    const [slug,set_slug] = useState('')
    const [page,set_page] = useState('1')
    const [sort,set_sort] = useState('date')
    const [dir,set_dir] = useState('up')

    useEffect(()=>{
      if (router?.query) {
        set_user_name(router?.query?.user_name?.toString())
        set_slug(router?.query?.slug?.toString())
        set_page(router?.query?.page?.toString())
        set_sort(router?.query?.sort?.toString())
        set_dir(router?.query?.dir?.toString())
      }
    },[
      router?.query
    ])

    // const [menukeys,set_menukeys] = useState(Object.keys(profile.menuarray));

  // const auth = useUserContext()
  const [session,isLoading] = useSession()
  const [userdata, set_userdata] = useState(parseUserJson(JSON.stringify(session?.user?.all)))

  useEffect(()=>{
    set_userdata(parseUserJson(JSON.stringify(session?.user?.all)))
  },[session])


  const { userAuthData, isUserAuthLoading, isUserAuthError } = useUserAuth()

    //Chat states
    const [fullScreen, set_fullScreen] = useState(false)
    const [owner_id, set_owner_id] = useState(ssr_data_profile?.id)

    //follower action count
    const [followers_total,set_followers_total] = useState(ssr_data_profile?.followers_total)
    const [following_status,set_following_status] = useState(hasData(ssr_data_profile?.following_them) ? true : false)
    //const [user_name_h1,set_user_name_h1] = useState(user_name)
  


    //block action
    const [stream_url_active,set_stream_url_active] = useState('');

    useEffect(() => {
      set_fullScreen(false)
    }, []);

    useEffect(() => {
      set_stream_url_active(getStreamUrl(
                                          ssr_data_profile?.bol_stream_local,
                                          ssr_data_profile?.stream_url_active,
                                          ssr_data_profile?.stream_url,
                                          userAuthData?.listen_experience,
                                          player_state))

    }, [
      ssr_data_profile?.bol_stream_local
      ,ssr_data_profile?.stream_url_active
      ,ssr_data_profile?.stream_url,
      ,userAuthData?.listen_experience
    ]);

    //scrollable ref
    // const ref = useRef(null);




  //const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });                                 
  const [broadcast_id,set_broadcast_id] = useState(null)
  const [broadcastcache,set_broadcastcache] = useState(encodeURIComponent(+new Date))   

    //PROFILE DATA
    const { 
      data: profiledata
    , loading: profiledataisLoading
    , error: profiledataisError
                    } = useSWRWrapper(
                      user_name //id
                      ,`/api/private/profile/select?id=${router?.query?.user_name?.toString()}&cache=${change_cache}` //key
                      ,true //retry?
                      ,config.max_retries //max number of retries
                      ,config.retry_interval //retry interval
                      ,0 //profiledata_active?.bol_credentials?.toString() == "1" ? config.profile.stream_interval_host : config.profile.stream_interval_user  //refreshinterval (0=none)
                      )
  
    useEffect(() => {
      if (!profiledataisLoading && !profiledataisError && profiledata) {
        set_profiledata_active(profiledata)
        set_owner_id(profiledata?.id)
        set_followers_total(profiledata?.followers)
        if (hasData(profiledata?.following_them)) set_following_status(true)
        
        //lookup broadcast stats
        if (profiledata?.stream_status == 1 && profiledata?.broadcast_id > 0) {
          set_broadcast_id(profiledata?.broadcast_id)
        }
  
        //only update this once
        if (!profiledataisLoading 
            && !profiledataisError 
            && profiledata) {
  
              let new_url_active = getStreamUrl(
                                                profiledata?.bol_stream_local,
                                                profiledata?.stream_url_active,
                                                profiledata?.stream_url,
                                                userAuthData?.listen_experience,
                                                player_state)
          
          //only update this if it changed
          if (new_url_active !== stream_url_active) set_stream_url_active(new_url_active) 
         
        }


      }
    }, [
         profiledata
        ,profiledataisLoading
        ,profiledataisError
        ,stream_url_active
        ,userAuthData?.listen_experience
      ]);



  if (profiledataisError && profiledataisError?.status == "404") {

    return (
      <>
      <ProfileMissing
          user_name={user_name}
          userAuthData={userAuthData}
          isUserAuthLoading={isUserAuthLoading}
          isUserAuthError={isUserAuthError}
          navtype={navtype}
          helptype={helptype}
          ssr_data_tribe={ssr_data_tribe}
      />
      </>

      )
  }

    return ( 
      <>
      
      <ProfileLayout
        navtype={navtype}
        subnavtype={navtype}
        helptype={helptype}
        ssr_data_tribe={ssr_data_tribe}
        profiledata_active={profiledata_active}
        set_profiledata_active={set_profiledata_active}
        scheduledata_active={scheduledata_active}
        set_scheduledata_active={set_scheduledata_active}
        page={page}
        sort={sort}
        dir={dir}
        user_name={user_name}
        owner_id={owner_id}
        userAuthData={userAuthData}
        isUserAuthLoading={isUserAuthLoading}
        isUserAuthError={isUserAuthError}
        stream_url_active={stream_url_active}
        // player_state={player_state}
        // set_player_state={set_player_state}
        // fullScreen={fullScreen}
        broadcast_id={broadcast_id}
        broadcastcache={broadcastcache}
        set_broadcastcache={set_broadcastcache}
        display_view={display_view}
        set_display_view={set_display_view}
        change_cache={change_cache}
        set_change_cache={set_change_cache}
    >

          <div className="  text-white  bg-gray-900">

            {/* SLUG CONTENT */}

              {children}


        </div>
    

    </ProfileLayout>
      
      </>
      )
}

export default ProfileHomeContainer